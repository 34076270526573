import React from "react"

// Libraries
import styled from "styled-components"

// Components
import EmbedAsset from "./embed-asset"

const StyledMediaGrid = styled.div`
  .full-width {
    max-width: 640px;
    width: 100%;
  }
`

const MediaGrid = props => {
  const {
    data: { layout, media },
  } = props

  let columnsClass

  switch (layout) {
    case "2 columns":
      columnsClass = "col-12 col-sm-6 px-md-3"
      break

    case "3 columns":
      columnsClass = "col-12 col-sm-4 px-md-3"
      break

    case "4 columns":
      columnsClass = "col-12 col-sm-6 col-md-3 px-md-3"
      break

    case "full-width":
      columnsClass = "full-width px-0"
      break

    default:
      columnsClass = "col-12 col-sm-6 px-md-3" // 👈🏻 defaultea a 2 columnas
      break
  }

  return (
    <StyledMediaGrid>
      <div className="row justify-content-center">
        {media.map(asset => (
          <div className={`${columnsClass}`} key={asset.id}>
            <EmbedAsset data={asset} />
          </div>
        ))}
      </div>
    </StyledMediaGrid>
  )
}

export default MediaGrid
