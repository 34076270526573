import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import { colors } from "utils/variables"

const StyledHero = styled.section`
  padding: 104px 0 152px 0;
  // max-width: 832px;
  // width: 100%;

  ${breakpoint.small`
    padding: 208px 0 140px 0;
  `}

  .hero__title {
    max-width: 850px !important;
    width: 100%;
  }

  .hero__details {
    li {
      &::after {
        content: "";
        width: 8px;
        height: 8px;
        position: relative;
        top: -1px;
        display: inline-block;
        margin: 0 8px;
        background-color: ${colors.indiblue};
        border-radius: 50%;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .hero__tags {
    li {
      &::after {
        content: "|";
        display: inline-block;
        margin: 0 8px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
`

const Hero = props => {
  const { title, client, year, location, tags } = props

  return (
    <StyledHero className="bg--supernova color--midnight">
      <Container>
        <div className="row justify-content-center">
          <div className="hero__title col-12 col-sm-10 col-md-9 px-3">
            <h1 className="h2 mb-4">{title}</h1>

            <ul className="hero__details d-flex flex-wrap align-items-center paragraph--small mb-2">
              <li>
                <Trans>{client}</Trans>
              </li>

              <li>
                <Trans>{location}</Trans>
              </li>

              <li>
                <Trans>{year}</Trans>
              </li>
            </ul>

            <ul className="hero__tags d-flex flex-wrap align-items-center paragraph--small">
              {tags?.map(tag => (
                <li key={tag}>
                  <Trans>{tag}</Trans>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </StyledHero>
  )
}

export default Hero

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  tag: PropTypes.array.isRequired,
}
