import React from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Components
import CaseStudyHero from "components/case-study-hero/"
import FeaturedImage from "./featured-image"

const Hero = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CaseStudyHero
        title={t("Bringing Paraguay’s first 100% digital bank to life")}
        details={["Kapital", "Asunción, Paraguay", "2021"]}
        tags={[
          t("Brand Narrative Workshop"),
          t("Visual Branding"),
          t("Design Sprint"),
        ]}
        backgroundColor={colors.supernova}
        color={colors.midnight}
        bulletColor={colors.indiblue}
      />
      <FeaturedImage />
    </React.Fragment>
  )
}
export default Hero
