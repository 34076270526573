import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import CaseStudyHero from "components/case-study-hero/"
import FeaturedImage from "components/case-study-featured-image/"
import FeaturedMedia from "components/case-studies/featured-media"
import Container from "components/container/"

// Icons
import IconArrow from "assets/icons/icon-arrow-right.inline.svg"
import IconCursor from "assets/icons/cursor-open.svg"

const StyledNextProject = styled(Link)`
  position: relative;
  display: block;
  background-color: ${colors.midnight};
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: url("${IconCursor}") 45 45, default;

  &:hover {
    background-color: ${colors.supernova};
    color: ${colors.midnight};

    header {
      background-color: ${colors.supernova};
      color: ${colors.midnight};

      .hero__details {

        span {

          &::after {
            background-color: ${colors.indiblue} !important;
          }
        }
      }
    }

    .next-case-study__button-wrapper {
      color: ${colors.midnight};

      .icon {
        g {
          circle {
            fill: ${colors.indiblue} !important;
          }

          path {
            stroke: ${colors.white} !important;
          }
        }
      }
    }
  }

  .next-case-study-wrapper {
    position: relative;
    margin-bottom: -30%;

    ${breakpoint.medium`
      margin-bottom: -370px;
    `}

    section {
      margin-bottom: 0;
    }
  }

  header {
    background-color: ${colors.midnight};
    color: ${colors.white};
    transition: all 0.3s ease;

    .hero__details {

      span {

        &::after {
          background-color: ${colors.supernova} !important;
          transition: all 0.3s ease;
        }
      }
    }
  }

  .next-case-study__button-wrapper {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    margin: auto;
    color: ${colors.white};
    transition: all 0.3s ease;
    z-index: 50;

    ${breakpoint.medium`
      top: 80px;
    `}

    .icon {
      * {
        transition: all 0.3s ease;
      }

      g {
        circle {
          fill: ${colors.supernova} !important;
        }

        path {
          stroke: ${colors.midnight} !important;
        }
      }
    }
  }
`

const Hero = (props) => {
  return (
    <React.Fragment>
      <StyledNextProject to={props?.url}>
        <div className="next-case-study__button-wrapper">
          <Container>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-8">
                <p className="d-flex align-items-center paragraph--small font-weight--500">
                  <IconArrow className="icon me-2" />
                  Visit next case study
                </p>
              </div>
            </div>
          </Container>
        </div>

        <div className="next-case-study-wrapper">
          <CaseStudyHero
            title={props.title}
            details={props.details}
            tags={props.tags}
            backgroundColor={colors.supernova}
            color={colors.midnight}
            bulletColor={colors.indiblue}
          />
          <FeaturedImage>
            <FeaturedMedia {...props.image} />
          </FeaturedImage></div>
      </StyledNextProject>

    </React.Fragment>
  )
}
export default Hero
