import React from "react"

// Libraries
import styled from "styled-components"
// import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

// Icons
import Caret from "assets/icons/icon-quotes-caret.svg"

const StyledQuote = styled.div`
  max-width: 640px;
  width: 100%;
  margin: 48px auto 0px auto;

  ${breakpoint.medium`
    margin: 135px auto 0px auto;
  `}
  
  .quote {
    width: 100%;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    padding: 24px;
    border-radius: 8px;
    position: relative;
    fotn-weigth: 500;
    margin-bottom: 32px;

    &::before {
        content: url("${Caret}");
        position: absolute;
        bottom: -31px;
        left: 24px;
      }
  
      ${breakpoint.small`
        text-align: left;
      `}
  }

  .author__details {
      display: flex;
      
      .author__image {
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: flex-start;
        margin-right: 16px;
        border-radius: 50%;
        overflow: hidden;
      }

      .author__info {
        font-size: 18px;
        line-height: 1.4em;
  
        .name {
          font-weight: 700;
        }
      }
  }

`

const CaseStudyQuote = props => {
  const {
    data: {
      target: {
        authorsJobPosition,
        authorsName,
        quote: { quote },
        authorsProfilePicture: { gatsbyImageData },
      },
    },
  } = props
  const image = getImage(gatsbyImageData)
  return (
    <StyledQuote
      backgroundColor={colors.indiblue}
      color={colors.white}
      className="col-12 col-md-8"
    >
      <div className="quote">
        <p>{quote}</p>
      </div>
      <div className="author__details">
        <div className="author__image">
          <GatsbyImage image={image} alt={authorsName} />
        </div>
        <div className="author__info">
          <p className="name">{authorsName}</p>
          <p>{authorsJobPosition}</p>
        </div>
      </div>
    </StyledQuote>
  )
}

export default CaseStudyQuote
