import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Trans } from "react-i18next"

// Components
import EmbedAsset from "./embed-asset"
import MediaGrid from "./media-grid"
import SwitchContent from "./custom-switch-content"
import CaseStudyQuote from "components/case-studies/quote"
import Container from "components/container"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints/"

const StyledSection = styled.section`
  ${Container} {
    max-width: 1332px;
    width: 100%;
  }

  color: ${colors.midnight};
  padding: 36px 0 40px 0;
  background-color: ${props =>
    props.theme === "Cream" ? colors.cream : colors.white};

  ${breakpoints.medium`
    padding: 100px 0;
  `}

  .content {
    .title {
      margin-left: auto;
      margin-right: auto;
      max-width: 640px;
    }

    h2 {
      max-width: 640px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    h3 {
      max-width: 640px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-weight: 500;

      & + p {
        margin-top: 24px;

        b {
          margin-top: 120px;
          display: block;
        }
      }
    }

    .content__paragraph {
      p {
        max-width: 640px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      p:empty {
        display: none;
      }

      a {
        color: ${colors.indiblue};
      }

      b {
        font-size: 20px;
        font-weight: 500px;
        line-height: 28px;

        ${breakpoints.medium`
          font-size: 24px;
        `}
      }
    }

    .media-grid {
      margin-top: 32px;
      ${breakpoints.medium`
        margin-top: 80px;
      `}

      & + .media-grid,
      & + .embed-asset {
        margin-top: 16px;

        ${breakpoints.medium`
          margin-top: 32px;
        `}
      }

      & + p {
        margin-top: 56px;
        margin-bottom: 56px;

        ${breakpoints.medium`
          margin-top: 120px;
          margin-bottom: 120px;
        `}
      }

      .asset-gif {
        display: block;
        padding: 0 6px;
      }

      .row {
        row-gap: 16px;
        ${breakpoints.medium`
          row-gap: 32px;
        `}
      }
    }

    .switch-content {
      & + p {
        margin-top: 56px;
        margin-bottom: 48px;

        ${breakpoints.medium`
          margin-top: 56px;
          margin-bottom: 120px;
        `}
      }
    }
  }
`

const Section = props => {
  const { title, content, sectionType } = props

  const renderOptions = {
    // Add <br/> between paragraphs
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },

    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        /**
         * @Juanso
         * Acá tenes que distinguir entre JSON files, imágenes y videos (Lottie, GatsbyImage,)
         * dos componentes: EmbedAssets() & MediaGrid
         */
        const {
          data: { target },
        } = node
        switch (target.__typename) {
          case "ContentfulAsset":
            return (
              <div className="media-grid">
                <EmbedAsset data={target} />
              </div>
            )
          default:
            break
        }
      },

      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="paragraph--small">{children}</p>
      ),

      [MARKS.BOLD]: (node, children) => <p className="h3">{children}</p>,

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const {
          data: { target },
        } = node
        /**
         * @Juanso @Tini
         * Acá tienen que agregar `case`s para ver si la
         * EMBEDDED_ENTRY es del tipo del componente que les tocó
         */

        if (target) {
          switch (target.__typename) {
            case "ContentfulMediaGrid":
              return (
                <div className="media-grid">
                  <MediaGrid data={target} />
                </div>
              )

            case "ContentfulCaseStudyQuote":
              return (
                <div>
                  <CaseStudyQuote {...node} />
                </div>
              )

            case "ContentfulSwitchContent":
              return (
                <div className="switch-content">
                  <SwitchContent data={target} />
                </div>
              )
            default:
              break
          }
        }
      },
    },
  }

  return (
    <StyledSection sectionType={sectionType}>
      <Container>
        <div className="content">
          <p className="color--indiblue title mb-2 mb-md-4 font-weight--500">
            <Trans>{title}</Trans>
          </p>
          <div className="content__paragraph">
            {content && renderRichText(content, renderOptions)}
          </div>
        </div>
      </Container>
    </StyledSection>
  )
}

export default Section

Section.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  sectionType: PropTypes.arrayOf(),
}
