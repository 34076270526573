import React from "react"

// Libraries
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Container from "components/container/"
import breakpoints from "utils/breakpoints"

const StyledFeaturedMedia = styled.div`
  display: flex;
  margin-top: -80px;
  padding-bottom: 40px;

  ${breakpoints.medium`
    padding-bottom: 80px;
  `}

  ${Container} {
    max-width: 1320px;
    width: 100%;
  }
`

const FeaturedMedia = props => {
  const { featuredMedia: media } = props

  return (
    <StyledFeaturedMedia>
      <Container>
        <GatsbyImage image={getImage(media)} alt="" quality={100} />
      </Container>
    </StyledFeaturedMedia>
  )
}

export default FeaturedMedia
