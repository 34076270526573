import React from "react"

// Libraries
import styled from "styled-components"

// Components
import Lottie from "components/lottie-observed"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StyledEmbedAsset = styled.div`
  & .asset-lottie,
  & .asset-lottie {
    margin-top: 0;
  }

  .asset-image {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
  }
`

/**
 * Si es una imagen:
 *   Si NO es GIF: usas <GatsbyImage />
 *   Sino: usas <img />
 *
 * Si es un video:
 *   Usas un <video />
 *
 * Si es un JSON:
 *   Usas <LottieObserved />
 */
const EmbedAsset = props => {
  const { data } = props
  return (
    <StyledEmbedAsset>
      {data.file.contentType === "video/mp4" ? (
        <iframe title="Indicius" src={data.file.url} allowFullScreen></iframe>
      ) : data.file.contentType === "application/json" ? (
        <div className="asset-lottie">
          <Lottie options={data.file.url} path={data.file.url} loop />
        </div>
      ) : data.file.contentType === "image/gif" ? (
        <div className="asset-gif">
          <img src={data.file.url} alt="Indicius" />
        </div>
      ) : (
        <div className="asset-image">
          <GatsbyImage
            image={getImage(data.gatsbyImageData)}
            quality={100}
            fluid={100}
            pngQuality={100}
          />
        </div>
      )}
    </StyledEmbedAsset>
  )
}

export default EmbedAsset
