import React from "react"

// Libraries
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Components
import breakpoints from "utils/breakpoints"

const StyledSwitchContent = styled.div`
  padding: 48px 0;
  max-width: 1328px;
  width: 100%;

  ${breakpoints.medium`
    padding: 64px 0;
  `}

  p {
    margin-bottom: 8px;
  }

  .switch-image {
    margin-top: 32px;

    ${breakpoints.medium`
      margin-top: 0;
    `}
  }
`

const SwitchContent = props => {
  const { data } = props

  return (
    <StyledSwitchContent>
      {data.imageStyle === "On the right" ? (
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <p className="paragraph--small">{renderRichText(data.content)}</p>
          </div>
          <div className="col-12 col-md-6 switch-image">
            <GatsbyImage image={getImage(data.image)} quality={100} />
          </div>
        </div>
      ) : (
        <div className="row flex-md-row-reverse align-items-center">
          <div className="col-12 col-md-6">
            <p className="paragraph--small">{renderRichText(data.content)}</p>
          </div>
          <div className="col-12 col-md-6 switch-image">
            <GatsbyImage
              image={getImage(data.image)}
              quality={100}
              pngQuality={100}
            />
          </div>
        </div>
      )}
    </StyledSwitchContent>
  )
}

export default SwitchContent
