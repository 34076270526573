import React, { useEffect, useState } from "react"

// Libraries
import { graphql } from "gatsby"
import PropTypes from "prop-types"

//Utils
import { colors } from "utils/variables"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Layout
import LayoutPrimary from "layouts/layout-primary"

// Components
import Hero from "components/case-studies/hero"
import Section from "components/case-studies/section"
import FeaturedMedia from "components/case-studies/featured-media"
import ContextConsumer from "components/context/"
import Cta from "components/case-study-call-to-action/"
import HeroCms from 'sections/case-studies/HeroCms'
import NextProject from "components/case-study-next-project"
import NextProjectHero from "sections/case-studies/kapital/hero"

const CaseStudy = props => {
  // Props
  const {
    data: {
      contentfulCaseStudy: data,
      spanishPosts: { nodes: spanishPosts },
      englishPosts: { nodes: englishPosts },
      posts: { nodes: posts }
    },
  } = props
  const { node_locale, title, id } = data

  const currentIndex = posts.findIndex(post => post.id === id);
  const isLastElement = (currentIndex === posts.length - 1);

  let nextPost = {}
  let nextPostDetails = []

  if (currentIndex !== -1) {
    nextPost = isLastElement ? posts[0] : posts[currentIndex + 1];
    nextPostDetails = [nextPost?.client, nextPost?.location, nextPost?.year]
  }

  const { t } = useTranslation()

  // States
  const [spanishLink, setSpanishLink] = useState(null)
  const [englishLink, setEnglishLink] = useState(null)

  useEffect(() => {
    if (node_locale === "en") {
      setSpanishLink(
        `/work/${spanishPosts[englishPosts.findIndex(post => post.title === title)]
          .slug
        }/`
      )
    } else {
      setEnglishLink(
        `/work/${englishPosts[spanishPosts.findIndex(post => post.title === title)]
          .slug
        }/`
      )
    }
  }, [])

  return (
    <LayoutPrimary
      menuColor={colors.supernova}
      englishLink={englishLink}
      spanishLink={spanishLink}
    >
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (!data.darkMenu) {
            set({ darkMenu: true })
          }
          if (data.showFooter) {
            set({ showFooter: false })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Hero {...data} />
      <FeaturedMedia {...data} />

      {data?.sections?.map(section => (
        <Section key={section.id} {...section} />
      ))}
      <Cta
        title={t(
          "Do you need to revamp the UX/UI of an already popular App, and really stand out?"
        )}
      />
      {isLastElement ? <NextProject url="/work/kapital" nextProjectHero={<NextProjectHero />} />
        : <HeroCms title={nextPost.title} details={nextPostDetails} tags={nextPost.categories} image={nextPost} url={`/work/${nextPost.slug}`} />
      }
    </LayoutPrimary>
  )
}

export default CaseStudy

CaseStudy.propTypes = {
  data: PropTypes.shape({
    contentfulCaseStudy: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      client: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query($id: String, $language: String) {
    contentfulCaseStudy(id: { eq: $id }) {
      id
      title
      client
      tags
      node_locale
      location
      year
      services
      featuredMedia {
        gatsbyImageData(width: 1328, quality: 100)
      }

      sections {
        title
        content {
          raw
          references {
            ... on ContentfulAsset {
              id
              __typename
              contentful_id
              gatsbyImageData(quality: 100)
              file {
                contentType
                url
                fileName
              }
              fluid {
                src
              }
            }

            ... on ContentfulMediaGrid {
              __typename
              id
              contentful_id
              layout
              media {
                description
                gatsbyImageData(quality: 100)
                file {
                  contentType
                  url
                }
              }
            }

            ... on ContentfulCaseStudyQuote {
              id
              contentful_id
              __typename
              title
              quote {
                quote
              }
              authorsName
              authorsJobPosition
              authorsProfilePicture {
                gatsbyImageData(width: 48, height: 48, quality: 100)
              }
            }

            ... on ContentfulSwitchContent {
              id
              __typename
              contentful_id
              imageStyle
              content {
                raw
              }
              image {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }

    englishPosts: allContentfulCaseStudy(
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        title
        slug
      }
    }

    spanishPosts: allContentfulCaseStudy(
      filter: { node_locale: { eq: "es" } }
    ) {
      nodes {
        title
        slug
      }
    }

    posts: allContentfulCaseStudy(
      filter: {
        node_locale: { eq: $language }
        title: { ne: "Case Study Template" }
      }
    ) {
      nodes {
        id
        title
        client
        slug
        year
        node_locale
        location
        categories: tags
        new
        featuredMedia {
        gatsbyImageData(width: 1328, quality: 100)
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
